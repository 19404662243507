import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Media from "../components/media"
import ProjectTiles from "../components/project-tiles"
import { ImageText, TeamBlock, PartnerLogo } from "../components/modular-blocks"
import { Helmet } from "react-helmet"

export const projectQuery = graphql`
  query allDatoCmsPage($slug: String) {
    page: datoCmsPage(slug: { eq: $slug }) {
      title
      shortSummary
      description
      featureImage {
        gatsbyImageData(imgixParams: { w: "2000", h: "1200", fit: "crop" })
        format
        url(imgixParams: { w: "1200", h: "630", fit: "crop" })
        alt
        video {
          thumbnailUrl(format: gif)
        }
      }

      contentBlocks {
        ... on DatoCmsPartnerLogo {
          model {
            apiKey
          }
          partners {
            title
            link
            logo {
              gatsbyImageData(imgixParams: { w: "400", h: "400", fit: "crop" })
            }
          }
        }

        ... on DatoCmsTeamBlock {
          model {
            apiKey
          }
          id
          projectTeam {
            id
            name
            profile {
              gatsbyImageData(imgixParams: { w: "1000", h: "1300", fit: "crop" })
            }
            jobTitle
            description
          }
        }

        ... on DatoCmsBlockImageText {
          model {
            apiKey
          }
          id
          text
          imageOnLeft

          image {
            gatsbyImageData
            format
            url
            alt
            video {
              thumbnailUrl(format: gif)
            }
          }
        }
      }
    }
  }
`

const SingleProject = ({ data: { page } }) => {
  return (
    <div className="wrapper bg-light ">
      <Layout>
        <Helmet>
          <title defer={false}>{page.title + " – Luxe Haven Homes"}</title>
          <meta name="description" content={page.shortSummary} />
          <meta property="og:title" content={page.title + " – Luxe Haven Homes"} />
          <meta property="og:image" content={page.featureImage.url} />
        </Helmet>
        <div className=" pt-28">
          <div className=" intro  fluid ">
            <Media asset={page.featureImage} />

            <div
              className="text-lead my-10 sm:w-4/5 lg:w-2/3 mx-auto sm:text-center "
              dangerouslySetInnerHTML={{ __html: page.description }}
            />
          </div>

          {page.contentBlocks.map(block => (
            <>
              {block.model.apiKey === "block_image_text" && <ImageText map={block} />}
              {block.model.apiKey === "team_block" && <TeamBlock map={block} />}
              {block.model.apiKey === "partner_logo" && <PartnerLogo map={block} />}
            </>
          ))}

          <ProjectTiles title="Built by Luxe Haven Homes" />
        </div>
      </Layout>
    </div>
  )
}

export default SingleProject
